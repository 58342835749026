import ui from "@alpinejs/ui";
import { Livewire, Alpine } from "../../vendor/livewire/livewire/dist/livewire.esm";
import tippy from "tippy.js";
import Typewriter from "@marcreichel/alpine-typewriter";
import Autosize from "@marcreichel/alpine-autosize";
import UI from "@alpinejs/ui";
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://dc6b15a69033cf8dbc9925e2285eedc0@o4506065870127104.ingest.sentry.io/4506065893326848",
});

window.tippy = tippy;

Alpine.plugin(ui);
Alpine.plugin(Typewriter);
Alpine.plugin(Autosize);
Alpine.plugin(UI);

Alpine.magic("tooltip", (el) => (message) => {
    let instance = tippy(el, {
        content: message,
        trigger: "manual",
    });

    instance.show();

    setTimeout(() => {
        instance.hide();

        setTimeout(() => instance.destroy(), 150);
    }, 2000);
});

Alpine.directive("tooltip", (el, { expression }) => {
    tippy(el, {
        content: expression,
    });
});

window.Alpine = Alpine;

Livewire.start();
